<template>
  <footer class="footer">
    <div class="container tablet-container">
      <div class="row no-gutters justify-content-xl-center">
        <div class="col-xl-3 col-md-4 col-sm-12 footer__col">
          <ul class="menu-list menu-list_hidden">
            <li class="menu-list__item">
              <uc-link class="menu-list__text menu-list__text_link" :to="localePath({ name: 'promotion-page' })">
                {{ $t('Акции и распродажи') }}
              </uc-link>
            </li>
            <li class="menu-list__item">
              <uc-link
                class="menu-list__text menu-list__text_link"
                :to="localePath({ name: 'info-page', params: { slug: 'shops' } })"
              >
                {{ $t('Наши магазины') }}
              </uc-link>
            </li>
            <li class="menu-list__item">
              <uc-link class="menu-list__text menu-list__text_link" :to="localePath({ name: 'blog-page' })">
                {{ $t('Блог') }}
              </uc-link>
            </li>
            <li class="menu-list__item menu-list__item-over">
              <uc-link
                title="Гарантия и сервис"
                aria-label="Гарантия и сервис"
                :to="localePath({ name: 'info-page', params: { slug: 'service-warranty' } })"
                class="menu-list__text menu-list__text_link"
              >
                {{ $t('Гарантия и сервис') }}
              </uc-link>
            </li>
            <li class="menu-list__item">
              <uc-link
                title="Ваши вопросы (FAQ)"
                aria-label="Ваши вопросы (FAQ)"
                :to="localePath({ name: 'info-page', params: { slug: 'faq' } })"
                class="menu-list__text menu-list__text_link"
              >
                {{ $t('Ваши вопросы (FAQ)') }}
              </uc-link>
            </li>
            <li class="menu-list__item">
              <uc-link
                title="'Пакунок малюка'"
                aria-label="'Пакунок малюка'"
                :to="localePath({ name: 'pakunok-malyuka-page' })"
                class="menu-list__text menu-list__text_link"
              >
                {{ $t('Пакунок малюка') }}
              </uc-link>
            </li>
          </ul>
          <div class="link-list link-list_mobile">
            <a
              href="https://www.instagram.com/ma.com.ua.official"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
              aria-label="Смотрите нас в Instagram"
              class="link-list__item link-list__item_mobile"
            >
              <svg class="instagram"><use xlink:href="#instagram" /></svg>
            </a>
            <a
              href="https://www.youtube.com/channel/UCsw6-nYN5gkfer4qe6pX7Qg/featured"
              target="_blank"
              rel="noreferrer"
              aria-label="Смотрите нас в Youtube"
              title="Youtube"
              class="link-list__item link-list__item_mobile"
            >
              <svg class="youtube">
                <use xlink:href="#youtube" />
              </svg>
            </a>
            <a
              href="https://www.facebook.com/ma.com.ua.official"
              target="_blank"
              rel="noreferrer"
              title="Facebook"
              aria-label="Смотрите нас в Facebook"
              class="link-list__item link-list__item_mobile"
            >
              <svg class="facebook">
                <use xlink:href="#facebook" />
              </svg>
            </a>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 col-sm-12 footer__col">
          <ul class="menu-list menu-list_hidden">
            <li class="menu-list__item">
              <uc-link
                title="Программа МА Кешбєк"
                aria-label="Программа МА Кешбєк"
                :to="localePath({ name: 'info-page', params: { slug: 'bonus-rules' } })"
                class="menu-list__text menu-list__text_link"
              >
                {{ $t('Программа МА Кешбєк') }}
              </uc-link>
            </li>
            <li class="menu-list__item">
              <uc-link
                title="Подарочные сертификаты"
                aria-label="Подарочные сертификаты"
                :to="localePath({ name: 'info-page', params: { slug: 'gift-sertificate' } })"
                class="menu-list__text menu-list__text_link"
              >
                {{ $t('Подарочные сертификаты') }}
              </uc-link>
            </li>
            <li class="menu-list__item">
              <uc-link
                title="О магазине"
                :to="localePath({ name: 'info-page', params: { slug: 'about-shop' } })"
                aria-label="О магазине"
                class="menu-list__text menu-list__text_link"
              >
                {{ $t('О нас') }}
              </uc-link>
            </li>
            <li class="menu-list__item">
              <uc-link
                aria-label="Доставка и оплата"
                title="Доставка и оплата"
                :to="localePath({ name: 'info-page', params: { slug: 'delivery' } })"
                class="menu-list__text menu-list__text_link"
              >
                {{ $t('Доставка и оплата') }}
              </uc-link>
            </li>
            <li class="menu-list__item menu-list__item-over">
              <uc-link
                title="Условия покупки"
                aria-label="Условия покупки"
                :to="localePath({ name: 'info-page', params: { slug: 'purchase-terms' } })"
                class="menu-list__text menu-list__text_link"
              >
                {{ $t('Условия покупки') }}
              </uc-link>
            </li>
            <li class="menu-list__item menu-list__item-over">
              <uc-link
                title="Политика конфиденциальности"
                aria-label="Политика конфиденциальности"
                :to="localePath({ name: 'info-page', params: { slug: 'privacy-policy' } })"
                class="menu-list__text menu-list__text_link"
              >
                {{ $t('Политика конфиденциальности') }}
              </uc-link>
            </li>
          </ul>
          <div class="link-list link-list_mobile link-list_hidden">
            <span class="link-list__item"
              ><img
                src="https://static.ma.com.ua/ma/svg/visa.svg"
                height="11"
                width="35"
                class="payment-icon"
                alt="img"
            /></span>

            <span class="link-list__item"
              ><img
                src="https://static.ma.com.ua/ma/svg/mastercard.svg"
                height="22"
                width="35"
                class="payment-icon"
                alt="img"
            /></span>
          </div>
        </div>
        <div class="col-xl-4 col-md-12 col-sm-12 footer__col">
          <div class="dispatch-wrap">
            <UcForm form-class="dispatch-form" :handler="submit">
              <uc-input
                v-model="userEmail"
                rules="required|email"
                class="dispatch-form__field"
                name="email"
                type="email"
                label="Е-mail"
                no-label
                placeholder="Ваш E-mail"
              />
              <uc-loader-button class="dispatch-form__submit" :loading="loading" :disabled="loading" type="submit">{{
                $t('Подписаться')
              }}</uc-loader-button>
            </UcForm>
            <div class="dispatch__description">
              {{ $t('Подпишитесь и получайте новости об акциях и специальных предложениях') }}
            </div>
          </div>
        </div>
        <div class="col col-m-f footer__col">
          <div class="footer-info footer-info_m1">
            <span class="footer-info-head__title">{{ $t('Наши контакты') }}:</span>
            <div class="footer-phone">
              <a class="footer-phone__link" href="tel:0443230906">(044) 323-09-06</a>
            </div>
          </div>
          <div class="footer-info footer-info_m2">
            <div class="footer-info-content footer-info-content_mobile">
              <span class="footer-info-content__text">{{ $t('пн-вс: с') }} 09:00 {{ $t('до') }} 20:00</span>
            </div>
          </div>
          <div class="footer-info footer-info_m3">
            <div class="footer-info-head footer-info-head-1920">
              <span class="footer-info-head__title">E-mail:</span>
            </div>
            <div class="footer-info-content">
              <a href="mailto:support@ma.com.ua" class="footer-info-content__text">support@ma.com.ua</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottomline">
      <div class="container tablet-container">
        <div class="row no-gutters">
          <div class="col">
            <div class="footer-created">
              <div class="copyright">
                &trade; MA&reg; &copy; 2021-{{ new Date().getFullYear() }} "MA". {{ $t('Все права защищены') }}.
              </div>
              <div class="version">Branch {{ branch }}, version {{ version }} at {{ buildTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FacebookIcon from '~/assets/img/svg/social/fb_new.svg';
import InstagramIcon from '~/assets/img/svg/social/insta_new.svg';
import YoutubeIcon from '~/assets/img/svg/social/youtube_new.svg';

const USER_ALREADY_EXIST_EMAIL = 1211;

export default {
  data() {
    return {
      version: process.env.version,
      branch: process.env.branch,
      buildTime: process.env.buildTime,
      userEmail: null,
      FacebookIcon: FacebookIcon,
      InstagramIcon: InstagramIcon,
      YoutubeIcon: YoutubeIcon,
      loading: false
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.$api.UserService.eSputnikSubscribe('footer', this.userEmail)
        .then(() => {
          this.$eventBus.$emit('subscribe:modal:show');
          this.$gtm.setESEmailAction(this.userEmail);
          this.userEmail = null;
        })
        .catch((error) => {
          if (error.response && error.response.data.code && error.response.data.code === USER_ALREADY_EXIST_EMAIL) {
            this.$eventBus.$emit('user-exists:modal:show');
          } else {
            console.error('Subscribe Error:', error);
          }
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/_dispatch.scss" />
<style lang="scss" src="~/assets/scss/_footer.scss" />
